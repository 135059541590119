import React from "react";
import Table from "../Components/Table/Table";

const Billing = () => {
  return (
    <div>
      <Table />
    </div>
  );
};

export default Billing;
